import React from 'react';
import { Box, Button, Flex, Heading, Text } from '@chakra-ui/react';
import { GatsbyImage as Img, getImage } from 'gatsby-plugin-image';
import { graphql, useStaticQuery } from 'gatsby';
import { IoArrowForward } from '@react-icons/all-files/io5/IoArrowForward';
import UpcomingCourses from '../components/home/upcoming-courses';
import BlogPostPreviews from '../components/common/blog-post-previews';
import SEO from '../components/seo';
import queryString from 'query-string';

const ProfessionalsPage = ({ data }) => {
  const professionalCourses = data.courses.edges.filter(
    (course) => !course?.node?.subcategory?.includes('parents')
  );
  const seoTitle = data.pageSeo.edges[0].node.pageName;
  const seoDescription =
    data.pageSeo.edges[0].node.description.internal.content;
  return (
    <>
      <SEO title={seoTitle} description={seoDescription} />
      <Flex
        flexDir="column"
        justifyContent="flex-start"
        alignItems="flex-start"
        w="100%"
        mt={6}
        mb={16}
      >
        <Flex
          justifyContent="space-between"
          alignItems="flex-start"
          w="100%"
          mb={16}
          flexDir={{ base: 'column', lg: 'row' }}
        >
          <Flex
            justifyContent="space-between"
            alignItems="flex-start"
            flexDir="column"
            w="100%"
            mr={16}
            maxW="600px"
            order={{ base: 2, lg: 1 }}
          >
            <Flex
              w="100%"
              flexDir="column"
              justifyContent="flex-start"
              flexGrow={1}
            >
              <Heading as="h1" mt={10}>
                {data.header.heading}
              </Heading>
              <Text color="gray.600" mt={6}>
                {data.header.introParagraph.internal.content}
              </Text>
            </Flex>
          </Flex>
          <Box
            w="100%"
            maxW={{ base: '100%', lg: '50%' }}
            borderRadius="lg"
            overflow="hidden"
            boxShadow="lg"
            order={{ base: 1, lg: 2 }}
          >
            <Img
              image={getImage(data.header.headerImage.gatsbyImage)}
              alt={data.header.headerImage.title}
            />
          </Box>
        </Flex>
        <UpcomingCourses courses={professionalCourses} />
        <BlogPostPreviews />
      </Flex>
    </>
  );
};

export default ProfessionalsPage;

export const query = graphql`
  query {
    pageSeo: allContentfulPageSeo(
      filter: { pageName: { eq: "for-professionals" } }
    ) {
      edges {
        node {
          pageName
          description {
            internal {
              content
            }
          }
        }
      }
    }
    header: contentfulEntry(contentful_id: { eq: "5UTtxkpA8C1AVitRFjvTIl" }) {
      ... on ContentfulPageHeader {
        id
        heading
        introParagraph {
          internal {
            content
          }
        }
        headerImage {
          title
          gatsbyImage(width: 720)
        }
      }
    }

    courses: allContentfulCourse {
      edges {
        node {
          subcategory
          courseCategory
          courseImage {
            title
            gatsbyImage(width: 400)
          }
          courseName
          shortDescription {
            raw
          }
          slug
          forParents
        }
      }
    }
  }
`;
